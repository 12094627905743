import { useParams } from "react-router-dom";

const useCourseNavigation = (courseIds: number[]) => {
  const params = useParams<{ id: string }>();

  const currentCourseId = courseIds?.indexOf(Number(params.id)) ?? null;
  const previousCourseId =
    currentCourseId > 0 ? courseIds[currentCourseId - 1] : null;
  const nextCourseId =
    currentCourseId < (courseIds?.length ?? 0) - 1
      ? courseIds[currentCourseId + 1]
      : null;

  return {
    currentCourseId,
    previousCourseId,
    nextCourseId,
  };
};

export default useCourseNavigation;
