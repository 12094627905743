import { UserService } from "../../../APIService/UserService";
import ProfileCenteredButton from "../../../components/profile/ProfileCenteredButton";
import ProfileInputWithLabel from "../../../components/profile/ProfileInputWithLabel";
import { useUserInfoContext } from "../../../context/userContext";
import { TTableColumn } from "../../../types";
import styles from "./profileInformation.module.scss";
import { useSnackbar } from "notistack";

const profileInformationColumns: TTableColumn[] = [
  {
    label: "Email",
    type: "email",
    id: "email",
  },
  {
    label: "Имя",
    type: "firstName",
    id: "firstName",
  },
  {
    label: "Фамилия",
    type: "lastName",
    id: "lastName",
  },
  {
    label: "Ник",
    type: "nickname",
    id: "nickname",
  },
];

export function ProfileInformation() {
  const { userInfo, handleChange } = useUserInfoContext();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    await UserService.updateUserInfo(userInfo)
      .then(() => {
        enqueueSnackbar("Данные успешно сохранены", {
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      })
      .catch((e) => {
        enqueueSnackbar(e.response.data.message || e.response.data.error, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      });
  };

  return (
    <>
      <h3>Информация о профиле</h3>

      <hr />

      <form className={styles.Form} onSubmit={onSubmit}>
        {profileInformationColumns.map((x: TTableColumn) => {
          return (
            <ProfileInputWithLabel
              key={x.id}
              {...{
                id: x.id,
                label: x.label,
                type: x.type,
                value: userInfo[x.id as keyof typeof userInfo],
                handleChange,
              }}
            />
          );
        })}

        <ProfileCenteredButton
          label={"Сохранить"}
          onClick={onSubmit}
          type={"submit"}
          disabled={false}
        />
      </form>
    </>
  );
}
