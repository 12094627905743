import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseService from "../../../APIService/CourseService";
import TaskService from "../../../APIService/TaskService";
import { CoursesNavigationButtons } from "../../../components/task/CoursesNavigationButtons";
import TaskList from "../../../components/task/TaskList";
import { CourseTypeEnum } from "../../../enums";
import { useCourseContext } from "../../../context/courseContext";
import useCourseNavigation from "../../../shared/hooks/useCourseNavigation";
import Loading from "../../../shared/ui/loading/loading";
import styles from "./courseTaskPage.module.scss";

const getCourseTypeFromURL = (): CourseTypeEnum => {
  const pathname = window.location.pathname;
  const parts = pathname.split("/").filter((part) => part.trim() !== "");

  if (parts.length > 0) {
    const firstWord = parts[0].toUpperCase();

    if (Object.values(CourseTypeEnum).includes(firstWord as CourseTypeEnum)) {
      return firstWord as CourseTypeEnum;
    }
  }

  return CourseTypeEnum.COURSE;
};

export function CourseTaskPage() {
  const courseType = getCourseTypeFromURL();
  const { setCourse, course, setTaskList, taskList, setCourseIds, courseIds } =
    useCourseContext();
  const params = useParams();
  const [isCoursesLoading, setIsCoursesLoading] = useState<boolean>(false);
  const { previousCourseId, nextCourseId } = useCourseNavigation(courseIds);

  useEffect(() => {
    if (!params.id) {
      setIsCoursesLoading(false);
      setTaskList([]);
      return;
    }

    const fetchCourse = async (id: string) => {
      setIsCoursesLoading(true);
      setTaskList([]);

      const response = await TaskService.getCourse(id);
      setCourse(response.data.course);
      setIsCoursesLoading(false);
      setTaskList(response.data.tasks);
    };

    fetchCourse(params.id);
  }, [params.id, setTaskList, setCourse, setIsCoursesLoading]);

  useEffect(() => {
    if (courseType === CourseTypeEnum.COURSE) {
      return;
    }

    const fetchCourseIds = async () => {
      const response = await CourseService.getAllCourseIds(courseType);
      setCourseIds(response.data);
    };

    fetchCourseIds();
  }, [courseType, setCourseIds]);

  return (
    <div className={styles.Wrapper}>
      {isCoursesLoading ? (
        <div className={styles.centeredContent}>
          <Loading />
        </div>
      ) : (
        <TaskList tasks={taskList} course={course} />
      )}

      {courseType !== CourseTypeEnum.COURSE ? (
        <CoursesNavigationButtons
          previousCourseId={previousCourseId}
          nextCourseId={nextCourseId}
          courseType={courseType}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
