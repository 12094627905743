import React from "react";
import TeamMemberForm from "../../../components/ourTeam/TeamMemberForm";
import { TeamMember } from "../types/teamMember";
import styles from "./ourTeamContainer.module.scss";

export function OurTeamContainer() {
  const members: TeamMember[] = [
    {
      name: "Фефелова Ольга",
      role: "Руководитель проекта",
      description:
        "Санкт-Петербургский государственный университет. Факультет прикладной математики.",
      imageSrc: "images/our-team/Fefelova_Olga.png",
    },
    {
      name: "Барышников Слава",
      role: "Технический директор",
      description:
        "Санкт-Петербургский государственный университет. Факультет прикладной математики.",
      imageSrc: "images/our-team/Baryshnikov_Vyacheslav.png",
    },
    {
      name: "Игнаткин Вячеслав",
      role: "Руководитель направлений",
      description:
        "ЕГЭ, ОГЭ и Олимпиадная математика. МИФИ. Факультет атомной энергетики и машиностроения. 298 баллов на ЕГЭ.",
      imageSrc: "images/our-team/Ignatkin_Vyacheslav.png",
    },
    {
      name: "Артюхов Антон",
      role: "Преподаватель",
      description: "Олимпиадная математика. Ученик 9 класса ФМЛ 239.",
      imageSrc: "images/our-team/Artuhov_Anton.png",
    },
    {
      name: "Галкин Игорь",
      role: "Преподаватель 3-11 класс",
      description:
        "Олимпиадная математика. Санкт-Петербургский государственный университет. Физический факультет, кандидат физико-математических наук.",
      imageSrc: "images/our-team/Galkin_Igor.png",
    },
    {
      name: "Грачёв Пётр",
      role: "Преподаватель 6-8 класс",
      description:
        "Олимпиадная математика. ИТМО. Факультет информационных технологий и программирования. Выпускник ФМЛ 239.",
      imageSrc: "images/our-team/Grachev_Petr.png",
    },
    {
      name: "Гуль Татьяна",
      role: "Преподаватель 4-11 класс",
      description:
        "Олимпиадная математика, ОГЭ, ЕГЭ. Челябинский Педагогический Институт. Математический факультет.",
      imageSrc: "images/our-team/Ghoul_Tatyana.png",
    },
    {
      name: "Евдокимов Сергей",
      role: "Преподаватель 3-9 класс",
      description:
        "Олимпиадная математика. Санкт-Петербургский государственный университезический факультет.",
      imageSrc: "images/our-team/Evdokimov_Sergey.png",
    },
    {
      name: "Ефремова Светлана",
      role: "Тестировщик",
      description: "Проверка новой функциональности и оцифровка задач.",
      imageSrc: "images/our-team/Efremova_Svetlana.png",
    },
    {
      name: "Жидков Александр",
      role: "Программист",
      description:
        "Студент 1 курса ИТМО. Факультет информационных технологий и программирования.",
      imageSrc: "images/our-team/Zhidkov_Aleksandr.png",
    },
    {
      name: "Ковалев Максим",
      role: "Дизайнер",
      description:
        "Санкт-Петербургский государственный институт культуры. Кафедра информационных технологий и компьютерного дизайна.",
      imageSrc: "images/our-team/Kovalev_Maksim.png",
    },
    {
      name: "Корнеев Алексей",
      role: "Юрист",
      description:
        "Новгородский государственный университет имени Ярослава Мудрого. Юридический факультет.",
      imageSrc: "images/our-team/Korneev_Alexey.png",
    },
    {
      name: "Жидкова Наталья",
      role: "Аналитик",
      description:
        "Санкт-Петербургский государственный университет. Экономический факультет. Выпускница ФМЛ 239.",
      imageSrc: "images/our-team/Korf_Natalya.png",
    },
    {
      name: "Лаврикова Елена",
      role: "Финансист",
      description:
        "Санкт-Петербургский горный университет. Экономический факультет.",
      imageSrc: "images/our-team/Lavrikova_Elena.png",
    },
    {
      name: "Липатова Ирина",
      role: "Преподаватель 4-8 класс",
      description:
        "Санкт-Петербургский государственный университет. Физический факультет.",
      imageSrc: "images/our-team/Lipatova_Irina.png",
    },
    {
      name: "Образцова Анастасия",
      role: "Аналитик",
      description:
        "Классификация, оцифровка и решение задач. Санкт-Петербургский Политехнический университет. Экономический факультет.",
      imageSrc: "images/our-team/Obraztsova_Anastasiya.png",
    },
    {
      name: "Орловская Маргарита",
      role: "Аналитик",
      description:
        "Классификация, оцифровка и решение задач. Санкт-Петербургский Политехнический университет.",
      imageSrc: "images/our-team/Orlovskaya_Margarita.png",
    },
    {
      name: "Рожкова Ольга",
      role: "Преподаватель 2-4 класс",
      description:
        "Петрозаводский государственный университет. Факультет промышленное и гражданское строительство. Занимается обучением детей с 2016 года.",
      imageSrc: "images/our-team/Rozhkova_Olga.png",
    },
    {
      name: "Сайранова Оксана",
      role: "Интернет-маркетолог",
      description:
        'Специалист по контекстной рекламе и цифровому маркетингу. Сертифицированный специалист Яндекс Директ. Окончила курс "Цифровой маркетинг" University System of Maryland',
      imageSrc: "images/our-team/Sayranova_Oksana.png",
    },
    {
      name: "Сычев Денис",
      role: "Ведущий программист",
      description:
        "Санкт-Петербургский государственный университет. Физический факультет.",
      imageSrc: "images/our-team/Sychev_Denis.png",
    },
    {
      name: "Тарасова Анна",
      role: "Преподаватель 4-8 класс",
      description:
        "Олимпиадная математика. Студентка МФТИ, призёр ВСОШ по математике. выпускница лицея «Вторая школа» (Москва).",
      imageSrc: "images/our-team/Tarasova_Anna.png",
    },
    {
      name: "Финдейзен Юлия",
      role: "Преподаватель 3-7 класс",
      description:
        "Олимпиадная математика. Санкт-Петербургский государственный университет. Факультет прикладной математики.",
      imageSrc: "images/our-team/Suvorova_Ulia.png",
    },
    {
      name: "Ханмагомедова Мелек",
      role: "Аналитик",
      description:
        "Решение задач. Ученица 7 класса, Москва. Активный участник и призер математических олимпиад.",
      imageSrc: "images/our-team/Hanmagomedova_Melek.png",
    },
    {
      name: "Хусточка Евгений",
      role: "Программист",
      description:
        "Казахский университет международных отношений и мировых языков. Синхронный переводчик с английского языка. Самостоятельное изучение программирования.",
      imageSrc: "images/our-team/Hustochka_Evgeniy.png",
    },
    {
      name: "Чернова Антонина",
      role: "Преподаватель 1-4 класс",
      description:
        "Олимпиадная математика. Студентка 4 курса ИТМО. Факультет информационных технологий и программирования.",
      imageSrc: "images/our-team/Chernova_Antonina.png",
    },
    {
      name: "Шпадарук Наталья",
      role: "Преподаватель 1-3 класс",
      description:
        "Псковский Педагогический Институт. Математический факультет.",
      imageSrc: "images/our-team/Shpandaruk_Natalya.jpg",
    },
    {
      name: "Штейнберг Илья",
      role: "Преподаватель 1-4 класс",
      description:
        "Олимпиадная математика. Студент 4 курса Санкт-Петербугского государственного университета телекоммуникаций.",
      imageSrc: "images/our-team/Shtenberg_Ilya.png",
    },
  ];

  return (
    <div className={styles.Wrapper}>
      {members.map((member) => (
        <TeamMemberForm
          key={member.name}
          name={member.name}
          role={member.role}
          description={member.description}
          imageSrc={member.imageSrc}
        />
      ))}
    </div>
  );
}
