import React, { ReactNode, useState } from "react";
import styles from "./dropDown.module.scss";
import { Menu, Dropdown as MUIDropdown, MenuButton, MenuItem } from "@mui/base";
import classNames from "classnames";

export interface IDropDownProps {
  text: string;
  items: {
    key: string;
    label: ReactNode;
    onClick?: () => void;
  }[];
  buttonClassName?: string;
  menuClassName?: string;
}

function DropDown(props: IDropDownProps) {
  const { text, items, buttonClassName, menuClassName } = props;
  const [open, setOpen] = useState(false);

  return (
    <MUIDropdown open={open} onOpenChange={(_, isOpen) => setOpen(isOpen)}>
      <MenuButton className={classNames(styles.header, buttonClassName)}>
        {text}
      </MenuButton>

      <Menu className={classNames(styles.menu, menuClassName)}>
        {items.map((item) => (
          <MenuItem
            key={item.key}
            onClick={() => {
              if (item.onClick) {
                item.onClick();
              }
              setOpen(false);
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </MUIDropdown>
  );
}

export default DropDown;
