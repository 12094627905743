import { useNavigate } from "react-router-dom";
import ProfileCenteredButton from "../../../components/profile/ProfileCenteredButton";
import styles from "./profilePage.module.scss";
import { InvoiceService } from "../../../APIService/InvoiceService";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export function PaymentsForm() {
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("auth") || "{id:0}").id;
    InvoiceService.getInvoices(id).then((response) => {
      setInvoices(response.data);
    });
  }, []);

  return (
    <>
      <h3>Платежи</h3>
      <hr />

      <div className={styles.Title}>
        {invoices.map((invoice: any) => {
          return (
            <div key={invoice.id}>
              <div>
                <b>Счет:</b> {invoice.description}
              </div>
              <div>
                <b>Сумма:</b> {invoice.sum}
              </div>
              <div>
                <b>Статус:</b> {invoice.status}
              </div>
              <div>
                {invoice.status === "UNPAID" ? (
                  <Link
                    state={invoice}
                    to={
                      "/payment?sum=" +
                      invoice.sum +
                      "&description=" +
                      invoice.description +
                      "&invoiceId=" +
                      invoice.id
                    }
                  >
                    Оплатить
                  </Link>
                ) : (
                  <></>
                )}
              </div>
              <br />
            </div>
          );
        })}
        <br />
        <br />
      </div>

      <ProfileCenteredButton
        label={"Новый платеж"}
        type={"submit"}
        onClick={() => {
          navigate("/payment");
        }}
      />
    </>
  );
}
