import { useState, useEffect, useDeferredValue } from "react";
import { Link } from "react-router-dom";
import SchoolsList from "../../../components/schoolList";
import { Button } from "../../../shared/ui/button";
import { TSchoolOutput } from "../../../types";
import { fetchSchools } from "../api/fetchSchools";
import styles from "./entryWorks.module.scss";

export function EntryWorks() {
  const [schools, setSchools] = useState<TSchoolOutput[]>([]);
  const deferredSchools = useDeferredValue(schools);

  useEffect(() => {
    fetchSchools().then((schools) => {
      setSchools(schools);
    });
  }, []);

  return (
    <div className={styles.Wrapper}>
      <div>
        <h2>ВСТУПИТЕЛЬНЫЕ РАБОТЫ</h2>

        <p>
          Задания официальных вступительных работ с 2009 по 2021 годы и их
          аналоги, созданные с помощью генератора задач minimath239.
        </p>

        <p>
          На 22 января 2025 года опубликовано 180 вступительных экзаменов в 5-е
          классы.
        </p>

        <SchoolsList schools={deferredSchools} />

        <Link to={"/exam"}>
          <Button>Все школы</Button>
        </Link>
      </div>

      <img
        width="278"
        height="303"
        className={styles.Image}
        src={"/images/inputwork3.svg"}
        alt=""
      />
    </div>
  );
}
