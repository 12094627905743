import { isAdmin } from "../../helpers/role";
import { Button } from "../../shared/ui/button";
import styles from "./Task.module.scss";

interface ITaskEditButton {
  access: string;
  link: string;
}

function TaskEditButton(props: ITaskEditButton) {
  const { access, link } = props;

  if (isAdmin(access)) {
    return (
      <Button
        type="link"
        className={styles.EditLink}
        onClick={() => window.open(link, "_blank")}
      >
        Редактировать
      </Button>
    );
  }

  return <></>;
}

export default TaskEditButton;
