import { TableRow, Tooltip } from "@mui/material";
import React from "react";
import { TTableColumn } from "../../../types";
import CustomTableCell from "./tableCellMaterial";

type TCustomTableRowProps = {
  row: any;
  columns: TTableColumn[];
  component?: any;
  linkTo?: string;
  tooltip?: string;
  onClick?: (e: any) => void;
};

function CustomTableRow<T>(props: TCustomTableRowProps) {
  const { row, columns, component, linkTo, tooltip, onClick } = props;
  const tableRow = (
    <TableRow
      onClick={onClick}
      component={component}
      linkto={linkTo}
      hover
      tabIndex={-1}
      key={row.id}
    >
      {columns.map((column) => {
        const value = row[column.id as keyof T];
        return (
          <CustomTableCell
            key={column.id.toString() + row.id}
            column={column}
            rowNumber={row.id}
            value={value}
            format={column.format}
            linkTo={column.linkTo}
            expandable={column.expandable}
          />
        );
      })}
    </TableRow>
  );

  return tooltip ? (
    <Tooltip
      title={tooltip}
      enterDelay={2000}
      enterNextDelay={2000}
      key={row.id || row.course_id}
      leaveDelay={0}
    >
      {tableRow}
    </Tooltip>
  ) : (
    tableRow
  );
}

export default CustomTableRow;
