import React from "react";
import CustomTableMaterial from "../../shared/ui/table/customTableMaterial";
import { TAnswer25Type } from "../../types";
import { answer25Columns } from "../../displayColumnsConfig";
import { RowsPerPageEnum } from "../../displayColumnsConfig";

function Answer25Table(props: { tableData: TAnswer25Type[] }) {
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(RowsPerPageEnum.ALL);

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  return (
    <CustomTableMaterial<TAnswer25Type>
      list={props.tableData || []}
      page={page}
      columns={answer25Columns}
      rowsPerPage={rowsPerPage}
      localPagination={true}
      // handleChangeRowsPerPage={handleChangeRowsPerPage}
      // handleChangePage={handleChangePage}
    />
  );
}

export default Answer25Table;
