import styles from "./Task.module.scss";
import React from "react";
import { TCourse } from "../../types";
import { Grid } from "@mui/material";

type TTaskFooterProps = {
  course: TCourse;
  isClickedCheck: boolean;
};

function TaskFooter(props: TTaskFooterProps) {
  const { course, isClickedCheck } = props;
  return (
    <div className={styles.TasksList}>
      <Grid item sm={11} md={11} lg={11}>
        <p
          dangerouslySetInnerHTML={{
            __html:
              (isClickedCheck ? course.aTextAfter : course.qTextAfter) || "",
          }}
        />
      </Grid>
    </div>
  );
}

export default TaskFooter;
