import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "../../../APIService/AuthService";
import AuthInput from "../../../components/login/AuthInput";
import { AuthTypeEnum } from "../../../enums";
import { useAuthContext } from "../../../context/authContext";
import { Button } from "../../../shared/ui/button";
import { TAuth } from "../../../types";
import styles from "./loginForm.module.scss";
import { useSnackbar } from "notistack";

export interface ILoginFormProps {
  type: string;
}

export function LoginForm(props: ILoginFormProps) {
  const { type } = props;

  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const { setIsAuth, setStudentId } = useAuthContext();
  const [state, setState] = useState<TAuth>({
    email: "",
    password: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const auth =
        type === AuthTypeEnum.LOGIN
          ? await AuthService.loginUser(state)
          : await AuthService.register(state);
      setIsAuth(!!localStorage.getItem("auth"));
      setStudentId(auth.id);
      if (locationState) {
        const { redirectTo } = locationState as { redirectTo: Location };
        navigate(`${redirectTo.pathname}${redirectTo.search}`);
      } else {
        navigate("/");
      }
    } catch (e: any) {
      enqueueSnackbar(e.response.data.message || e.response.data.error, {
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  };

  useEffect(() => {
    setState({ email: "", password: "" });
  }, [type]);
  const { email, password } = state;

  return (
    <div>
      <Grid
        direction={"column"}
        container
        className={styles.LoginContainer}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Grid item xs={12} sm={12} md={12} className={styles.Title}>
          {type === AuthTypeEnum.LOGIN ? (
            <h3>Войти в личный кабинет</h3>
          ) : (
            <h3>Зарегистироваться</h3>
          )}
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Grid item xs={12} sm={12} md={12}>
            <AuthInput
              placeholder="Email"
              name="email"
              type="email"
              value={email}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.Title} />
            <AuthInput
              type="password"
              placeholder="Пароль"
              name="password"
              value={password}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <div className={styles.Title} />

        <div className={styles.ButtonsHolder}>
          <Button onClick={handleSubmit} type={"submit"}>
            {type === AuthTypeEnum.LOGIN ? "Войти" : "Зарегистрироваться"}
          </Button>

          {type === AuthTypeEnum.LOGIN ? (
            <Link to={"/forgot-password"}>
              <Button>Забыли пароль? </Button>
            </Link>
          ) : (
            <div />
          )}
        </div>
      </Grid>
    </div>
  );
}
