import React, { useEffect, useState } from "react";
import CourseService from "../../../APIService/CourseService";
import { CourseLayer } from "../../../components/course/CourseLayer";
import { CourseName } from "../../../components/course/courseName";
import ExamHeader from "../../../components/course/ExamHeader";
import { CourseTypeEnum } from "../../../enums";
import { useCourseContext } from "../../../context/courseContext";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import { Button } from "../../../shared/ui/button";
import Loading from "../../../shared/ui/loading/loading";
import { Scroller } from "../../../shared/ui/scroller";
import { Sticker } from "../../../shared/ui/sticker";
import { TCourseThemes } from "../../../types";
import styles from "./examPage.module.scss";

export function ExamPage() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "exam");

  const ctx = useCourseContext();
  const { coursesList, setCoursesList } = ctx;

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await CourseService.getCourseGroupList({
        courseType: CourseTypeEnum.EXAM!,
        take: 150,
        page: 0,
      });
      setCoursesList(response.data);

      setIsCoursesLoading(false);
    };
    fetchCourses().then();
  }, [setCoursesList]);

  const [isCoursesLoading, setIsCoursesLoading] = useState<boolean>(true);

  return (
    <div className={styles.Exams}>
      <ExamHeader />

      {isCoursesLoading ? (
        <div className={styles.centeredContent}>
          <Loading />
        </div>
      ) : (
        <div className={styles.Wrapper}>
          <div id={"schoolsWrapper"} className={styles.ThemesWrapper}>
            {coursesList.map((x: TCourseThemes) => (
              <CourseName
                key={x.shortName || x.name}
                header={x.shortName || x.name}
              />
            ))}
          </div>

          <div className={styles.centeredArrowDown}>
            <img src={"/images/arrowdown.png"} alt={""} />
          </div>

          <div className={styles.ExamsCards}>
            {coursesList.length === 0 ? (
              <div>
                <p>Нет задач!</p>
              </div>
            ) : (
              coursesList.map((x: TCourseThemes) => (
                <CourseLayer
                  key={CourseTypeEnum.EXAM + (x.shortName || x.name)}
                  courseType={CourseTypeEnum.EXAM!}
                  header={x.shortName || x.name || ""}
                  courses={x.course}
                />
              ))
            )}
          </div>

          <Sticker className={styles.scrollButton}>
            <Scroller scrollToComponentName={"schoolsWrapper"}>
              <Button>Вернуться к перечню тем</Button>
            </Scroller>
          </Sticker>
        </div>
      )}
    </div>
  );
}
