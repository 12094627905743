export type TColSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12; // TODO: seems css thingie, no need the type
export type TCenteredButtonProps = {
  label: string;
  type: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
  loading?: boolean;
};

export type TLinkProps = {
  href: string;
  label: string;
  colSpan?: TColSpan;
  offset?: TColSpan;
  key?: string;
};

export type TProfileInputProps = {
  id: string;
  label: string;
  type?: string;
  value: string | number | boolean;
  handleChange: (e: any) => void;
};

export type TTableColumn = {
  id: string;
  align?: "right" | "left" | "center";
  minWidth?: number;
  label: string;
  type?: string;
  linkTo?: string;
  format?: (value: string) => string;
  expandable?: boolean; // should be no more than one as true
};

export enum CheckedCorrect {
  CORRECT = "CORRECT",
  WRONG = "WRONG",
}

export type TChecked = {
  taskId: number;
  isCorrect: CheckedCorrect;
  hint: string;
  rootId?: number;
  answerImage?: string;
  answerBase64Image?: string;
};

export type TCourse = {
  id: number;
  name: string;
  siteName: string;
  title: string;
  urlCode: string;
  text1?: string;
  text2?: string;
  text3?: string;
  text4?: string;
  header1: string;
  header2?: string;
  header3?: string;
  header4?: string;
  aTextBefore?: string;
  aTextAfter?: string;
  qTextBefore?: string;
  qTextAfter?: string;
  image?: string;
  base64CourseImage?: string;
};

export type TCourseThemes = {
  shortName: string | null;
  siteName: string | null;
  name: string;
  id: number | null;
  city: string | null;
  www: string | null;
  course: TCourse[];
  group: string;
};

export type TEditCourse = {
  id?: number;
  twin: number;
  url_code: string;
  program_id: string;
  order: number;
  school_id: number;
  name: string;
  title: string;
  description: string;
  site_name: string;
  header1: string;
  header2: string;
  header3: string;
  header4: string;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  a_text_before: string;
  a_text_after: string;
  comment: string;
  theme_type_id: number;
  image: string;
  active: number;
  tag_list: string;
  tag_task_list: string;
  type_form: number;
  task_count: number;
  type: number;
  verif_date: string;
  link: string;
  www: string;
  url_id: string;
  page_id: number;
  tmp_year: number;
  tmp_var: number;
  base64CourseImage?: string;
};

export type TEditTask = {
  id?: number;
  rootId: number;
  urlCode: string;
  question: string;
  questionImage: string;
  answer: string;
  answerImage: string;
  answerMask: string;
  answerType: string | null;
  solution: string;
  comment: string;
  source: number;
  year: number;
  var: number;
  img_src: string;
  tagList1: string;
  tagList2: string;
  tagList3: string;
  base64CourseImage?: string;
};

export interface TIdForCTM {
  id: string | number;
}
export interface TAssignedCoursesTableType extends TIdForCTM {
  course_id: number;
  course_name: string;
  true_cnt: string;
  wrong_cnt: string;
  empty_cnt: string;
}

export interface TAnswerLkType extends TIdForCTM {
  course_id: string;
  site_name: string;
  task_id: string;
  task_num: string;
  subtask_num: string;
  answer_count: string;
  answer: string;
  success: string;
  question: string;
}

export interface TStudentInfoType extends TIdForCTM {
  email: string;
  firstName: string;
  lastName: string;
  sysName: string;
}

export interface TAnswer25Type extends TIdForCTM {
  user_id: number;
  course_data: string;
  answer_date: string;
  course_id: string;
  order_task: string;
  task_id: string;
  count: number;
  success: string;
  user_answers: string;
  minimath_answer: string;
  order_for_sort: string;
  type_a: string;
  question: string;
  solution: string;
  sys_name: string;
  email: string;
}

export type TNotificationStateType = {
  resultType: "Success" | "Failure" | null;
  message: string | null;
};

export type TSchool = {
  id: number;
  shortName: string;
  name: string;
  city: string;
  www: string;
  siteName?: string;
};

export type TSchoolOutput = {
  group: string;
  items: TSchool[];
};

export type TTask = {
  id: number;
  name: string;
  rootId: number;
  answerType: string;
  answerMask: string;
  questionImage?: string;
  question: string;
  subj?: string;
  isAlreadySolved: boolean;
  subTasks?: TTask[];
  base64QuestionImage?: string;
  base64AnswerImage?: string;
  displayIndex?: number;
};

export type TUserAuth = {
  id: number;
  email: string;
  access: string;
  role: string;
  iat: number;
  exp: number;
};

export type TAuth = {
  email: "";
  password: "";
};

export type TUserInfo = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
};
