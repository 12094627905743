import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { TUserAuth } from "../types";
import { v4 } from "uuid";

export type TAuthContext = {
  isAuth: boolean;
  authInfo: TUserAuth;
  setIsAuth: Dispatch<SetStateAction<boolean>>;
  studentId: number;
  setStudentId: Dispatch<SetStateAction<number>>;
};

export const AuthContext = createContext<TAuthContext>({
  isAuth: false,
  authInfo: {
    id: 0,
    email: "",
    access: "",
    role: "",
    iat: 0,
    exp: 0,
  },
  setIsAuth: () => {},
  studentId: 0,
  setStudentId: () => {},
});

function AuthProvider(props: { children: any }) {
  const [isAuth, setIsAuth] = useState(false);
  const [userInfo, setUserInfo] = useState<TUserAuth>({
    id: 0,
    email: "",
    access: "",
    role: "",
    iat: 0,
    exp: 0,
  });
  const [studentId, setStudentId] = useState(0);

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth && !isNaN(Number(JSON.parse(auth).id))) {
      setIsAuth(true);
      setUserInfo(JSON.parse(auth));
      setStudentId(userInfo.id);
    } else {
      setIsAuth(false);
      setStudentId(0);
      if (!auth) {
        localStorage.setItem("auth", '{"id": "' + v4() + '"}');
      }
    }
  }, [isAuth, userInfo.id]);
  return (
    <AuthContext.Provider
      value={{ isAuth, setIsAuth, authInfo: userInfo, studentId, setStudentId }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
export const useAuthContext = () => {
  return useContext(AuthContext);
};
