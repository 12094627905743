type editCreateColumnType = {
  name: string;
  displayName: string;
  type:
    | "FormText"
    | "FormTextArea"
    | "FormLookup"
    | "FormImage"
    | "FormFile"
    | "FormTiny";
  readonly?: boolean;
  formType?: "text" | "checkbox" | "number";
  required?: boolean;
};

export const editCreateCourseColumns: editCreateColumnType[] = [
  {
    displayName: "id",
    name: "id",
    type: "FormText",
    readonly: true,
    formType: "number",
  },
  {
    displayName: "tmp_year",
    name: "tmpYear",
    type: "FormText",
    formType: "number",
  },
  {
    displayName: "url_code",
    name: "urlCode",
    type: "FormLookup",
    formType: "text",
  },
  {
    displayName: "school_id",
    name: "schoolId",
    type: "FormText",
    formType: "number",
  },
  { displayName: "order", name: "order", type: "FormText", formType: "number" },
  {
    displayName: "name",
    name: "name",
    type: "FormText",
    formType: "text",
    required: true,
  },
  {
    displayName: "comment",
    name: "comment",
    type: "FormText",
    formType: "text",
  },
  {
    displayName: "header1",
    name: "header1",
    type: "FormText",
    formType: "text",
  },
  {
    displayName: "task_count",
    name: "taskCount",
    type: "FormText",
    formType: "text",
  },
  {
    displayName: "program_id",
    name: "programId",
    type: "FormText",
    formType: "number",
  },
  {
    displayName: "page_id",
    name: "pageId",
    type: "FormText",
    formType: "text",
  },
  { displayName: "url_id", name: "urlId", type: "FormText", formType: "text" },
  { displayName: "title", name: "title", type: "FormText", formType: "text" },
  {
    displayName: "description",
    name: "description",
    type: "FormText",
    formType: "text",
  },
  {
    displayName: "q_text_before",
    name: "qTextBefore",
    type: "FormTiny",
    formType: "text",
  },
  {
    displayName: "q_text_after",
    name: "qTextAfter",
    type: "FormTiny",
    formType: "text",
  },
  {
    displayName: "a_text_before",
    name: "aTextBefore",
    type: "FormTiny",
    formType: "text",
  },
  {
    displayName: "a_text_after",
    name: "aTextAfter",
    type: "FormTiny",
    formType: "text",
  },
  { displayName: "twin", name: "twin", type: "FormText", formType: "text" },
  {
    displayName: "site_name",
    name: "siteName",
    type: "FormText",
    formType: "text",
  },
  {
    displayName: "theme_type_id",
    name: "themeTypeId",
    type: "FormText",
    formType: "number",
  },
  { displayName: "image", name: "image", type: "FormImage" },
  {
    displayName: "mod_date",
    name: "modDate",
    type: "FormText",
    readonly: true,
    formType: "text",
  },
  {
    displayName: "create_date",
    name: "createDate",
    type: "FormText",
    readonly: true,
    formType: "text",
  },
  {
    displayName: "active",
    name: "active",
    type: "FormText",
    formType: "number",
  },
  {
    displayName: "tag_list",
    name: "tagList",
    type: "FormText",
    formType: "text",
  },
  {
    displayName: "tag_task_list",
    name: "tagTaskList",
    type: "FormFile",
    formType: "text",
  },
  {
    displayName: "type_form",
    name: "typeForm",
    type: "FormText",
    formType: "text",
  },
  { displayName: "type", name: "type", type: "FormText", formType: "text" },
  {
    displayName: "verif_date",
    name: "verifDate",
    type: "FormText",
    readonly: true,
    formType: "text",
  },
  {
    displayName: "link",
    name: "link",
    type: "FormText",
    readonly: true,
    formType: "text",
  },
  { displayName: "www", name: "www", type: "FormText", formType: "text" },
  {
    displayName: "tmp_var",
    name: "tmpVar",
    type: "FormText",
    formType: "number",
  },
];

export const editCreateTaskColumns: editCreateColumnType[] = [
  { displayName: "ID", name: "id", type: "FormText", readonly: true },
  { displayName: "question_image", name: "questionImage", type: "FormImage" },
  { displayName: "question", name: "question", type: "FormTiny" },
  { displayName: "type_a", name: "answerType", type: "FormLookup" },
  { displayName: "mask_a", name: "answerMask", type: "FormText" },
  { displayName: "answer", name: "answer", type: "FormText" },
  { displayName: "answer_image", name: "answerImage", type: "FormImage" },
  { displayName: "version", name: "version", type: "FormTextArea" },
  { displayName: "solution", name: "solution", type: "FormTiny" },
  { displayName: "comment", name: "comment", type: "FormTiny" },
  // All below are not in the current production on minimath239.com
  {
    displayName: "rootId",
    name: "rootId",
    type: "FormText",
    formType: "number",
  },
  { displayName: "twin", name: "twin", type: "FormText", formType: "number" },
  {
    displayName: "parent_id",
    name: "parentId",
    type: "FormText",
    formType: "number",
  },
  {
    displayName: "theme_id",
    name: "themeId",
    type: "FormText",
    formType: "number",
  },
  { displayName: "order", name: "order", type: "FormText", formType: "number" },
  {
    displayName: "rating",
    name: "rating",
    type: "FormText",
    formType: "number",
  },
  { displayName: "year", name: "year", type: "FormText", readonly: true },
  { displayName: "var", name: "var", type: "FormText", readonly: true },
  {
    displayName: "tagList1",
    name: "tagList1",
    type: "FormText",
    readonly: true,
  },
  {
    displayName: "tagList2",
    name: "tagList2",
    type: "FormText",
    readonly: true,
  },
  {
    displayName: "tagList3",
    name: "tagList3",
    type: "FormText",
    readonly: true,
  },
];
