import { Navigate, useLocation } from "react-router-dom";

function LoggedInRoute(props: { children: any }) {
  const location = useLocation();
  const auth = localStorage.getItem("auth");
  if (!(auth && !isNaN(JSON.parse(auth).id))) {
    return <Navigate to="/login" replace state={{ redirectTo: location }} />;
  }

  return props.children;
}

export default LoggedInRoute;
