import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/authContext";
import DropDown from "../../../shared/ui/dropDown/dropDown";
import { isAdmin } from "../../../helpers/role";

interface IReportDropDownProps {
  linkPathAnswerLk: string;
  linkPathAnswer25: string;
  buttonClassName?: string;
  menuClassName?: string;
}

export function ReportDropDown(props: IReportDropDownProps) {
  const { linkPathAnswerLk, linkPathAnswer25, buttonClassName, menuClassName } =
    props;
  const { authInfo } = useAuthContext();
  const dropDownItems = [
    {
      key: "1",
      label: <Link to={linkPathAnswerLk}>Ответы</Link>,
    },
  ];

  if (isAdmin(authInfo.role)) {
    dropDownItems.push({
      key: "2",
      label: <Link to={linkPathAnswer25}>Отчет 25</Link>,
    });
  }

  return (
    <DropDown
      buttonClassName={buttonClassName}
      menuClassName={menuClassName}
      items={dropDownItems}
      text={"Отчеты"}
    />
  );
}
