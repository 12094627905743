import { useState } from "react";
import { TAnswerLkType } from "../../types";
import { answerReportColumns } from "../../displayColumnsConfig";
import CustomTableMaterial from "../../shared/ui/table/customTableMaterial";
import { RowsPerPageEnum } from "../../displayColumnsConfig";

function AnswerLkTable(props: { tableData: TAnswerLkType[] }) {
  const [page] = useState(0);
  const [rowsPerPage] = useState(RowsPerPageEnum.ALL);

  // set ids for keys in table
  let i = 1;
  props.tableData.forEach((r) => {
    if (!r.id) r["id"] = i++;
  });

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  return (
    <CustomTableMaterial<TAnswerLkType>
      list={props.tableData || []}
      page={page}
      columns={answerReportColumns}
      rowsPerPage={rowsPerPage}
      localPagination={true}
      // handleChangeRowsPerPage={handleChangeRowsPerPage}
      // handleChangePage={handleChangePage}
      groupBy="course_id"
      groupTitle="site_name"
    />
  );
}

export default AnswerLkTable;
