import { HashLink } from "react-router-hash-link";
import { TCourseThemes } from "../../../types";

import styles from "./courseThemesList.module.scss";

export interface ICourseThemesListProps {
  themes: TCourseThemes[];
}

export function CourseThemesList(props: ICourseThemesListProps) {
  const { themes } = props;
  const limitedThemes = themes.slice(0, 10);

  return (
    <ol className={styles.Table}>
      {limitedThemes.map((data) => (
        <li key={data.name} className={styles.Li}>
          <HashLink
            className={styles.ThemeLinks}
            to={`/theme#${data.shortName || data.name}`}
          >
            {data.shortName || data.name}
          </HashLink>
        </li>
      ))}
    </ol>
  );
}
