import { useState } from "react";
import { TAssignedCoursesTableType } from "../../types";
import {
  studyLkColumns,
  answerReportColumns,
  RowsPerPageEnum,
} from "../../displayColumnsConfig";
import CustomTableMaterial from "../../shared/ui/table/customTableMaterial";

function LkTable(props: {
  tableData: TAssignedCoursesTableType[];
  admin?: boolean;
}) {
  const [page] = useState(0);
  const [rowsPerPage] = useState(RowsPerPageEnum.ALL);

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  return (
    <div>
      {props.admin ? (
        <CustomTableMaterial<TAssignedCoursesTableType>
          list={props.tableData as TAssignedCoursesTableType[]}
          page={page}
          columns={studyLkColumns}
          localPagination={true}
          rowsPerPage={rowsPerPage}
          // handleChangeRowsPerPage={handleChangeRowsPerPage}
          // handleChangePage={handleChangePage}
        />
      ) : (
        <CustomTableMaterial<TAssignedCoursesTableType>
          list={props.tableData as TAssignedCoursesTableType[]}
          page={page}
          columns={answerReportColumns}
          rowsPerPage={rowsPerPage}
          // handleChangeRowsPerPage={handleChangeRowsPerPage}
          // handleChangePage={handleChangePage}
        />
      )}
    </div>
  );
}

export default LkTable;
