import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // TablePagination,
} from "@mui/material";
import { TIdForCTM, TTableColumn } from "../../../types";
import TableHeadRow from "./tableHeadMaterial";
import CustomTableRow from "./tableRowMaterial";

export type TCustomTableProps<T> = {
  list: T[];
  page: number;
  columns: TTableColumn[];
  rowsPerPage: number;
  tooltip?: string;
  linkTo?: string;
  count?: number;
  // handleChangeRowsPerPage: (e: any) => void;
  // handleChangePage: (e: any, page: any) => void;
  localPagination?: boolean;
  onClick?: (e: React.BaseSyntheticEvent) => void;
  groupBy?: string; // column name to group output by
  groupTitle?: string; // column name to use for output as a group title
};

function CustomTableMaterial<T extends TIdForCTM>(props: TCustomTableProps<T>) {
  const {
    list,
    page,
    rowsPerPage,
    localPagination,
    columns,
    tooltip,
    // handleChangePage,
    // handleChangeRowsPerPage,
    linkTo,
    onClick,
    groupBy,
    groupTitle,
  } = props;

  const dataToShow = !localPagination
    ? list
    : list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  let prevGroupByValue = "";

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableHeadRow
            columns={
              columns.filter((c) => c.expandable)
                ? [...columns, { id: "9999", label: "" }]
                : columns
            }
          />
        </TableHead>
        <TableBody>
          {dataToShow.map((row) => {
            let breakRow: JSX.Element | null = null;
            if (groupBy && (row as any)[groupBy] !== prevGroupByValue) {
              prevGroupByValue = (row as any)[groupBy];
              breakRow = (
                <TableRow key={"br" + row.id}>
                  <TableCell
                    key={"brc" + row.id}
                    sx={{ fontSize: "larger" }}
                    colSpan={
                      columns.length +
                      (columns.filter((c) => c.expandable) ? 1 : 0)
                    }
                  >
                    {groupTitle ? (row as any)[groupTitle] : ""}
                  </TableCell>
                </TableRow>
              );
            } else {
              breakRow = null;
            }
            return (
              <>
                {breakRow}
                <CustomTableRow
                  key={row.id}
                  component={typeof linkTo != "undefined" ? Link : undefined}
                  linkTo={
                    typeof linkTo != "undefined" ? linkTo! + row.id : undefined
                  }
                  tooltip={tooltip}
                  row={row}
                  columns={columns}
                  onClick={onClick}
                />
              </>
            );
          })}
        </TableBody>
      </Table>
      {/* <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={props.count || list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}

export default CustomTableMaterial;
