import { Link } from "react-router-dom";
import { CourseTypeEnum } from "../../enums";
import styles from "./CoursesNavigationButtons.module.scss";

interface ICoursesNavigationButtonsProps {
  previousCourseId: number | null;
  nextCourseId: number | null;
  courseType: CourseTypeEnum;
}

export function CoursesNavigationButtons({
  previousCourseId: previousId,
  nextCourseId: nextId,
  courseType,
}: ICoursesNavigationButtonsProps) {
  return (
    <div className={styles.Container}>
      {previousId && (
        <Link
          className={styles.Link}
          to={`/${courseType.toLowerCase()}/${previousId}`}
        >
          Предыдущий курс
        </Link>
      )}

      {nextId && (
        <Link
          className={styles.Link}
          to={`/${courseType.toLowerCase()}/${nextId}`}
        >
          Следующий курс
        </Link>
      )}
    </div>
  );
}
