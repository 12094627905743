import { useState } from "react";
import { UserService } from "../../../APIService/UserService";
import ProfileCenteredButton from "../../../components/profile/ProfileCenteredButton";
import ProfileInputWithLabel from "../../../components/profile/ProfileInputWithLabel";
import { useUserInfoContext } from "../../../context/userContext";
import styles from "./changePasswordForm.module.scss";
import { enqueueSnackbar } from "notistack";
import { TTableColumn } from "../../../types";

const changePasswordColumns: TTableColumn[] = [
  {
    label: "Пароль",
    type: "password",
    id: "password",
  },
  {
    label: "Повторите пароль",
    type: "password",
    id: "repeatedPassword",
  },
];

function checkEqualityPasswords(origin: string, repeated: string) {
  return origin === repeated;
}

export function ChangePasswordForm() {
  const { userInfo } = useUserInfoContext();
  const [disabled, setDisabled] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const handleChange = (event: any) => {
    const { value } = event.target;
    setNewPassword(value);
  };

  const handleChangeRepeated = (event: any) => {
    const { value } = event.target;
    setRepeatedPassword(value);
    if (checkEqualityPasswords(newPassword, value)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await UserService.updatePassword(userInfo.id, { newPassword });
    enqueueSnackbar("Password changed", {
      variant: "success",
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
  };

  return (
    <>
      <h3>Изменение пароля</h3>

      <hr />

      <form className={styles.Form} onSubmit={handleSubmit}>
        <ProfileInputWithLabel
          id={changePasswordColumns[0].id}
          label={changePasswordColumns[0].label}
          type={changePasswordColumns[0].type}
          value={newPassword}
          handleChange={handleChange}
        />

        <ProfileInputWithLabel
          id={changePasswordColumns[1].id}
          label={changePasswordColumns[1].label}
          type={changePasswordColumns[1].type}
          value={repeatedPassword}
          handleChange={handleChangeRepeated}
        />

        <ProfileCenteredButton
          disabled={disabled}
          label={"Сохранить пароль"}
          type={"submit"}
        />
      </form>
    </>
  );
}
