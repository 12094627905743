import { LkService } from "../../../APIService/LkService";
import LkTable from "../../../components/lk/LkTable";
import { useAuthContext } from "../../../context/authContext";
import {
  setDocumentTitle,
  useSiteNameContext,
} from "../../../context/siteNameContext";
import { useLoadingHook } from "../../../hooks/Hooks";
import Loading from "../../../shared/ui/loading/loading";
import { TAssignedCoursesTableType } from "../../../types";
import styles from "./lkInfoPage.module.scss";
import { useCallback } from "react";

export function LkInfoPage() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "lk");

  const { studentId } = useAuthContext();

  const tableDataFetch = useCallback(async () => {
    const res = await LkService.getLkInfo(studentId.toString());
    return res.data as TAssignedCoursesTableType[];
  }, [studentId]);

  const { result, pending } = useLoadingHook(tableDataFetch, studentId);
  return (
    <div className={styles.Wrapper}>
      {pending ? (
        <div className={"centeredContent"}>
          <Loading />
        </div>
      ) : (
        <div className={styles.LkTableContainer}>
          <h2 className={styles.Title}>Список курсов ученика {studentId}</h2>

          {result?.length ? (
            <LkTable tableData={result} admin={true} />
          ) : (
            <p className={styles.Title}>
              Отсутствуют назначенные на Вас курсы!
            </p>
          )}
        </div>
      )}
    </div>
  );
}
