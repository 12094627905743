import AuthInput from "../../components/login/AuthInput";
import ProfileCenteredButton from "../../components/profile/ProfileCenteredButton";
import styles2 from "../Page.module.scss";
import styles from "../../components/profile/Profile.module.scss";
import { useEffect, useState } from "react";
import { UserService } from "../../APIService/UserService";
import { enqueueSnackbar } from "notistack";
import { useLocation } from "react-router-dom";

function tinkoffPayFunction(target: any) {
  target.preventDefault();
  let form: any = document.getElementById("TinkoffPayFormId");
  let name = "Оплата";
  let amount = form?.amount.value;
  let email = form?.email.value;
  let phone = form?.phone.value;

  if (amount && email && phone) {
    form.receipt.value = JSON.stringify({
      Email: email,
      Phone: phone,
      EmailCompany: "minimath239@yandex.ru",
      Taxation: "patent",
      Items: [
        {
          Name: name,
          Price: amount + "00",
          Quantity: 1.0,
          Amount: amount + "00",
          PaymentMethod: "full_prepayment",
          PaymentObject: "service",
          Tax: "none",
        },
      ],
    });
    // @ts-ignore
    pay(form);
  } else {
    alert("Заполните все обязательные поля (со звездочкой)");
  }
  return target;
}

function PaymentPage() {
  const loc = useLocation();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [order, setOrder] = useState("");

  useEffect(() => {
    const authInfo = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth")!)
      : { id: 0 };
    UserService.getUserInfo(authInfo.id)
      .then((response) => {
        const userInfo = response.data;
        setEmail(userInfo.email);
        setName(userInfo.lastName + " " + userInfo.firstName);
        // const now = new Date(Date.now())
        // const order = "id" + userInfo.id.toString()+ "_" + now.getFullYear() + "_" + now.getMonth() + "_" + now.getDate() + "_" + now.getHours() + "_" + now.getMinutes() + "_" + now.getSeconds()
        setOrder("id" + userInfo.id.toString() + "_" + Date.now());
      })
      .catch((e) =>
        enqueueSnackbar(e.response.data.message || e.response.data.error, {
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        }),
      );
  }, []);

  let script = document.createElement("script");
  script.src = "https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js";
  script.type = "text/javascript";
  document.head.append(script);

  return (
    <div className={styles2.Wrapper}>
      <h3>Платеж</h3>

      <hr />
      <form className={styles.Form} name="TinkoffPayForm" id="TinkoffPayFormId">
        <input type="hidden" name="terminalkey" value="1726164495455DEMO" />
        <input type="hidden" name="frame" value="false" />
        <input type="hidden" name="language" value="ru" />
        <AuthInput
          label={"Сумма заказа"}
          name={"amount"}
          value={loc.state.sum}
          required
        />
        <AuthInput
          label={"Описание заказа"}
          name={"description"}
          value={loc.state.description}
        />
        <AuthInput label={"Контактный телефон"} name={"phone"} required />
        <AuthInput
          label={"Номер заказа"}
          name={"order"}
          value={order}
          onChange={(e: any) => {
            setOrder(e.target.value);
          }}
        />
        <AuthInput
          label={"ФИО плательщика"}
          name={"name"}
          value={name}
          onChange={(e: any) => {
            setName(e.target.value);
          }}
        />
        <AuthInput
          label={"E-mail"}
          name={"email"}
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
          required
        />
        <input type="hidden" name="receipt" value="" />

        <ProfileCenteredButton
          label={"Оплатить"}
          onClick={tinkoffPayFunction}
          type={"submit"}
          disabled={false}
        />
      </form>
    </div>
  );
}

export default PaymentPage;
