import React from "react";
import styles from "./ThemeHeader.module.scss";
import {
  getPageDecorations,
  setDocumentTitle,
  useSiteNameContext,
} from "../../context/siteNameContext";

function ThemeHeader() {
  const { siteNameList } = useSiteNameContext();
  setDocumentTitle(siteNameList, "/theme");
  const decorations = getPageDecorations(siteNameList, "/theme");

  return (
    <div className={styles.Header}>
      <h1 className={styles.Title}>{decorations?.header1}</h1>
    </div>
  );
}

export default ThemeHeader;
