import { TTableColumn } from "./types";

export const answerReportColumns: TTableColumn[] = [
  {
    id: "course_id",
    label: "Курс",
    minWidth: 50,
    align: "left",
    linkTo: "/course/",
  },
  // {
  //   id: "site_name",
  //   label: "Название",
  //   minWidth: 50,
  //   align: "right",
  // },
  {
    id: "task_id",
    label: "Код задачи",
    minWidth: 60,
    align: "right",
    linkTo: "/admin/task/edit/",
  },
  {
    id: "task_num",
    label: "№ задачи",
    minWidth: 50,
    align: "right",
  },
  {
    id: "subtask_num",
    label: "№ подзадачи",
    minWidth: 50,
    align: "right",
  },
  {
    id: "answer_count",
    label: "Ответов",
    minWidth: 50,
    align: "right",
  },
  {
    id: "answer",
    label: "Ответы",
    minWidth: 50,
    align: "right",
  },
  {
    id: "success",
    label: "Результаты",
    minWidth: 50,
    align: "right",
  },
  {
    id: "question",
    label: "Текст задач",
    minWidth: 50,
    align: "left",
    expandable: true,
    format: (s) => {
      const sWithoutHtml = s.replace(/(<([^>]+)>)/gi, "");
      return (
        sWithoutHtml.substring(0, 50) + (sWithoutHtml.length > 50 ? "..." : "")
      );
    },
  },
];

export const answer25Columns: TTableColumn[] = [
  {
    id: "course_data",
    label: "course_data",
    minWidth: 50,
    align: "left",
  },
  {
    id: "answer_date",
    label: "answer_date",
    minWidth: 50,
    align: "right",
  },
  {
    id: "course_id",
    label: "course_id",
    minWidth: 50,
    align: "right",
    linkTo: "/admin/course/edit/",
  },
  {
    id: "task_id",
    label: "task_id",
    minWidth: 50,
    align: "right",
    linkTo: "/admin/task/edit/",
  },
  {
    id: "count",
    label: "count",
    minWidth: 50,
    align: "right",
  },
  {
    id: "success",
    label: "success",
    minWidth: 50,
    align: "left",
  },

  {
    id: "user_answers",
    label: "user_answers",
    minWidth: 50,
    align: "left",
  },

  {
    id: "minimath_answer",
    label: "minimath_answer",
    minWidth: 50,
    align: "left",
  },

  {
    id: "question",
    label: "question",
    minWidth: 50,
    align: "left",
  },
];

export const studyLkColumns: TTableColumn[] = [
  {
    id: "course_id",
    label: "Курс",
    minWidth: 50,
    align: "left",
    linkTo: "/course/",
  },
  {
    id: "site_name",
    label: "Название",
    minWidth: 50,
    align: "left",
  },
  {
    id: "correct_cnt",
    label: "Верно",
    minWidth: 50,
    align: "left",
  },
  {
    id: "wrongcorrect_cnt",
    label: "Верно не с первой попытки",
    minWidth: 50,
    align: "left",
  },
  {
    id: "wrong_cnt",
    label: "Неверно",
    minWidth: 50,
    align: "left",
  },
  {
    id: "no_answer_cnt",
    label: "Нет ответов",
    minWidth: 50,
    align: "left",
  },
  {
    id: "course_task_cnt",
    label: "Всего",
    minWidth: 50,
    align: "left",
  },
  {
    id: "publish_date",
    label: "Дата",
    minWidth: 50,
    align: "left",
  },
];

export const lkTeacherColumns: TTableColumn[] = [
  {
    id: "id",
    label: "ID",
    minWidth: 50,
    align: "right",
  },
  {
    id: "firstName",
    label: "Имя",
    minWidth: 50,
    align: "right",
  },
  {
    id: "lastName",
    label: "Фамилия",
    minWidth: 50,
    align: "right",
  },
  {
    id: "sysName",
    label: "Sysname",
    minWidth: 50,
    align: "right",
  },

  {
    id: "email",
    label: "email",
    minWidth: 50,
    align: "center",
  },
];

export const rowsPerPageOptions = [
  {
    value: 1000000,
    label: "Все",
  },
];

export enum RowsPerPageEnum {
  ALL = 1000000,
}
