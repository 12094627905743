import { useDeferredValue, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../shared/ui/button";
import { TCourseThemes } from "../../../types";
import styles from "./olympiades.module.scss";
import CourseService from "../../../APIService/CourseService";
import { CourseTypeEnum } from "../../../enums";
import CourseThemesList from "../../../components/courseThemesList";

export function Olympiades() {
  const [themes, setThemes] = useState<TCourseThemes[]>([]);
  const deferredThemes = useDeferredValue(themes);

  const fetchThemes = async () => {
    const response = await CourseService.getCourseGroupList({
      courseType: CourseTypeEnum.THEME,
      take: 10,
      page: 0,
    });
    return response;
  };

  useEffect(() => {
    fetchThemes().then((response) => {
      setThemes([...response.data]);
    });
  }, []);

  return (
    <div className={styles.Wrapper}>
      <div>
        <h2 className={styles.Title}>ОЛИМПИАДНАЯ МАТЕМАТИКА</h2>

        <p className={styles.Text}>
          На 22 января 2025 года в публичном доступе 2000 задач для учеников 3-4
          класса, сгруппированных по 17 темам.
        </p>

        <CourseThemesList themes={deferredThemes.slice(0, 23)} />

        <Link to={"/theme"}>
          <Button>
            <span> Все темы</span>
          </Button>
        </Link>
      </div>
      <img
        width="295"
        height="253"
        className={styles.Image}
        src={"/images/movement2.svg"}
        alt=""
      />
    </div>
  );
}
