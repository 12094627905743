import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import TaskService from "../APIService/TaskService";
import {
  TCourse,
  TCourseThemes,
  CheckedCorrect,
  TChecked,
  TTask,
} from "../types";

/**
 * Контекст курсов/экзаменов
 */

type AnswerType = Record<string, string>;

export type TCourseContext = {
  taskList: any[];
  setTaskList: Dispatch<SetStateAction<TTask[]>>;
  setCourse: Dispatch<SetStateAction<TCourse>>;
  course: any;
  coursesList: TCourseThemes[];
  courseIds: number[];
  isClickedCheck: boolean;
  setIsClickedCheck: Dispatch<SetStateAction<boolean>>;
  setCourseIds: Dispatch<SetStateAction<number[]>>;
  setCoursesList: Dispatch<SetStateAction<TCourseThemes[]>>;
  checkedTasks: TChecked[];
  setCheckedTasks: Dispatch<SetStateAction<TChecked[]>>;
  handleSubmit: (e: any) => Promise<void>;
  handleChange: (e: any) => void;
  isCheckButtonDisabled: boolean;
  setCheckButtonDisabled: (e: any) => void;
  resetAll: () => void;
  answersList: AnswerType;
  setAnswersList: Dispatch<SetStateAction<AnswerType>>;
};

const CourseContext = createContext<TCourseContext>({
  taskList: [],
  setTaskList: () => {},
  setCourse: () => {},
  isClickedCheck: false,
  setIsClickedCheck: () => {},
  course: {},
  coursesList: [],
  courseIds: [],
  setCourseIds: () => {},
  setCoursesList: () => {},
  checkedTasks: [],
  setCheckedTasks: () => {},
  handleSubmit: async () => {},
  handleChange: () => {},
  isCheckButtonDisabled: false,
  setCheckButtonDisabled: () => {},
  resetAll: () => {},
  answersList: {},
  setAnswersList: () => {},
});

function CourseProvider(props: { children: any }) {
  const userInfo: any = JSON.parse(localStorage.getItem("auth")!).id.toString();

  const [taskList, setTaskList] = useState<TTask[]>([]);
  const [coursesList, setCoursesList] = useState<TCourseThemes[]>([]);
  const [courseIds, setCourseIds] = useState<number[]>([]);
  const [answersList, setAnswersList] = useState<AnswerType>({});
  const [course, setCourse] = useState<TCourse>({
    id: 0,
    urlCode: "",
    header1: "",
    title: "",
    name: "",
    siteName: "",
  });
  const [checkedTasks, setCheckedTasks] = useState<TChecked[]>([]);
  const [isCheckButtonDisabled, setCheckButtonDisabled] =
    useState<boolean>(true);
  const [isClickedCheck, setIsClickedCheck] = useState<boolean>(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setCheckedTasks([]);
    setIsClickedCheck(true);
    const apiAnswersPayload: Record<number, string | string[]> = {};
    Object.keys(answersList).forEach((k) => {
      const idAndIndex = k.split("_");
      const id = Number(idAndIndex[0].substring(2));
      if (idAndIndex.length === 1) {
        apiAnswersPayload[id] = answersList[k];
      } else {
        const index = Number(idAndIndex[1]);
        if (!apiAnswersPayload[id]) {
          apiAnswersPayload[id] = [];
        }
        (apiAnswersPayload[id] as string[])[index] = answersList[k];
      }
    });
    const a = await TaskService.postAnswersToCourse({
      courseId: course.id,
      answers: apiAnswersPayload,
      user: userInfo ? userInfo : "",
    });
    setCheckedTasks(a.data);

    // console.log(taskList.map((x) => x.id.toString() + x.isAlreadySolved ))
  };

  const handleChange = (e: any) => {
    const fieldId = e.target.id; // id999999 or id999999_99
    const value = e.target.value;
    setAnswersList({ ...answersList, [fieldId]: value });
  };

  const resetAll = () => {
    // console.dir(checkedTasks, {depth: null})
    // console.dir(answersList, {depth: null})
    // console.log("checkedTasks.length",checkedTasks.length)

    // before reset set isAlreadySolved for just solved tasks/subtasks
    checkedTasks.forEach((t) => {
      if (t.isCorrect === CheckedCorrect.CORRECT) {
        const task = taskList.find((x) => x.id === t.taskId);
        if (task) {
          task.isAlreadySolved = true;
        } else {
          let subTask: TTask | undefined;
          const taskWithSubTask = taskList.find(
            (x) =>
              x.subTasks &&
              (subTask = x.subTasks.find((y) => y.id === t.taskId)),
          );
          if (taskWithSubTask) {
            subTask!.isAlreadySolved = true;
          }
        }
      }
    });
    // console.log(taskList.map((x) => x.id.toString() + x.isAlreadySolved ))
    setIsClickedCheck(false);
    setAnswersList({});
  };

  return (
    <CourseContext.Provider
      value={{
        taskList,
        answersList,
        setAnswersList,
        setTaskList,
        setCourse,
        course,
        coursesList,
        isClickedCheck,
        setIsClickedCheck,
        courseIds,
        setCourseIds,
        setCoursesList,
        checkedTasks,
        setCheckedTasks,
        handleSubmit,
        handleChange,
        isCheckButtonDisabled,
        setCheckButtonDisabled,
        resetAll,
      }}
    >
      {props.children}
    </CourseContext.Provider>
  );
}

export default CourseProvider;

export const useCourseContext = () => {
  return useContext(CourseContext);
};
