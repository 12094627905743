import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { TTableColumn } from "../../../types";

function TableHeadRow(props: { columns: TTableColumn[] }) {
  return (
    <TableRow>
      {props.columns.map((column) => (
        <TableCell
          key={column.id}
          align={column.align}
          style={{ minWidth: column.minWidth }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default TableHeadRow;
