import styles from "./Task.module.scss";
import React from "react";
import { TCourse, TUserAuth } from "../../types";
import { Button } from "../../shared/ui/button";
import { isAdmin } from "../../helpers/role";

type TTaskHeaderProps = {
  course: TCourse;
  isClickedCheck: boolean;
  authInfo: TUserAuth;
};

function TaskHeader(props: TTaskHeaderProps) {
  const { course, authInfo, isClickedCheck } = props;
  const link = `/admin/course/edit/${course.id}`;
  return (
    <div>
      <div>
        <h2
          className={styles.TaskTitle}
          dangerouslySetInnerHTML={{ __html: course.header1 }}
        />
        {isAdmin(authInfo.role) ? (
          <Button
            className={styles.EditLink}
            onClick={() => window.open(link, "_blank")}
          >
            Редактировать
          </Button>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.Text}>
        <p
          dangerouslySetInnerHTML={{
            __html:
              (isClickedCheck ? course.aTextBefore : course.qTextBefore) || "",
          }}
        />
        {course.image || course.base64CourseImage ? (
          <img src={course.base64CourseImage} alt={course.image} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default TaskHeader;
