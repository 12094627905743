import { $host } from "./index";

export type PostCourseAnswerArgs = {
  courseId: number;
  answers: Record<number, string | string[]>;
  user: any;
};

export default class TaskService {
  static async getCourse(id: string) {
    return await $host.get("course/" + id, {
      params: { user: JSON.parse(localStorage.getItem("auth")!).id },
    });
  }

  static async postAnswersToCourse(args: PostCourseAnswerArgs) {
    return await $host.post("course/" + args.courseId, args);
  }
}
