import { CourseTypeEnum } from "../enums";
import { $host } from "./index";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

export type TPaginationArgs = {
  page: number;
  take: number;
};

export type TThemesArgs = TPaginationArgs & {
  courseType: CourseTypeEnum;
};

export default class CourseService {
  static async getThemesById(id: number, courseType: string) {
    return await $host.get(courseType.toLowerCase() + "/tasks/" + id);
  }

  static async getCourseGroupList(args: TThemesArgs) {
    const { courseType, page, take } = args;
    return await $host
      .get(
        `course/grouped-list/${courseType}`,
        { params: { page, take } },
        { headers },
      )
      .catch((e: unknown) => {
        console.log(e);
      });
  }

  static async getAllCourseIds(courseType: CourseTypeEnum) {
    return await $host.get(`course/all-ids/${courseType}`, {
      headers,
    });
  }
}
