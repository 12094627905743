export const AnswerTypeEnum = {
  SIMPLE: "simple",
  RADIO: "radio",
  NO_SPACES: "nospaces",
  REG_EXP: "regexp",
  ALG: "alg",
  ALG2: "alg2",
  NONE: "none",
};

// TODO: refactor next enums. 3 enums look too much.
export enum CourseTypeEnum {
  EXAM = "EXAM",
  THEME = "THEME",
  COURSE = "COURSE",
}

export enum CourseTypeEnumForEdit {
  EXAM = "EXAM",
  THEME = "THEME",
  NULL = "NULL",
}

export enum CourseLoadTypeEnum {
  EXAM = "EXAM",
  THEME = "THEME",
  ANY = "SHOULD_NOT_BE_IN_USE",
}

export const AuthTypeEnum = {
  LOGIN: "login",
  REGISTER: "register",
};

export function getEnumKeyByEnumValue(
  myEnum: any,
  enumValue: number | string,
): string {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : "NULL";
}
