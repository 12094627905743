import { Collapse, IconButton, TableCell } from "@mui/material";
import { MathJaxOutput } from "../../../components/mathJax/MathJaxOutput";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TTableColumn } from "../../../types";
import { useState } from "react";

type TCustomTableCellProps = {
  column: TTableColumn;
  rowNumber: any;
  value: string | number | boolean;
  linkTo?: string;
  format?: (value: string) => string;
  expandable?: boolean;
};

function CustomTableCell(props: TCustomTableCellProps) {
  const { column, value, format, rowNumber, linkTo, expandable } = props;
  const [open, setOpen] = useState(false);

  let stringValue = value?.toString() || "";
  if (stringValue === "0") {
    stringValue = "-";
  }
  if (format) {
    stringValue = format(stringValue);
  }

  const expand = expandable ? (
    <TableCell sx={{ verticalAlign: "top" }}>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpen(!open)}
      >
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </TableCell>
  ) : null;

  return (
    <>
      <TableCell
        sx={{ wordWrap: "anywhere", verticalAlign: "top" }}
        //key={column.id + rowNumber.id||rowNumber}
        align={column.align}
        id={rowNumber}
      >
        {
          <>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <MathJaxOutput
                text={
                  typeof linkTo !== "undefined" ? (
                    <a href={linkTo! + value}>{value}</a>
                  ) : (
                    value
                  )
                }
              />
            </Collapse>

            {!open ? (
              <MathJaxOutput
                text={
                  typeof linkTo !== "undefined" ? (
                    <a href={linkTo! + value}>{stringValue}</a>
                  ) : (
                    stringValue
                  )
                }
              />
            ) : null}
          </>
        }
      </TableCell>
      {expand}
    </>
  );
}

export default CustomTableCell;
